const theme = {
    colors: {
        MainColor: '#05422b',
        HoverColor: '#3CB371',
        accent: '#e0234e',
    },
    fontSize: {
        small: '12px',
        medium: '16px',
        large: '24px',
    },
    // 여기에 더 많은 테마 변수를 추가할 수 있습니다.
};

export default theme;
