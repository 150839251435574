// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Paging */\n\n#Paging_paging__ozdJv {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  margin: var(--margin-size-md) 0;\n}\n\n/* PagingNumber */\n\n.Paging_pagingNum__7Pqzl {\n  width: var(--font-size-h1);\n  height: var(--font-size-h1);\n  text-align: center;\n  line-height: var(--font-size-h1);\n  cursor: pointer;\n  margin: 0 var(--padding-height-sm);\n  border-radius: 5px;\n  transition: background-color 0.3s ease, font-weight 0.3s ease;\n}\n\n.Paging_pagingNum__7Pqzl:hover {\n  background-color: var(--border-color);\n  font-weight: 700;\n  border-radius: 5px;\n}\n\n.Paging_pagingNum__7Pqzl.Paging_selected__uzhzl {\n  background-color: var(--primary-color);\n  color: var(--white-color);\n  border-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/views/Paging/Paging.module.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,+BAA+B;AACjC;;AAEA,iBAAiB;;AAEjB;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,kCAAkC;EAClC,kBAAkB;EAClB,6DAA6D;AAC/D;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":["/* Paging */\n\n#paging {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  margin: var(--margin-size-md) 0;\n}\n\n/* PagingNumber */\n\n.pagingNum {\n  width: var(--font-size-h1);\n  height: var(--font-size-h1);\n  text-align: center;\n  line-height: var(--font-size-h1);\n  cursor: pointer;\n  margin: 0 var(--padding-height-sm);\n  border-radius: 5px;\n  transition: background-color 0.3s ease, font-weight 0.3s ease;\n}\n\n.pagingNum:hover {\n  background-color: var(--border-color);\n  font-weight: 700;\n  border-radius: 5px;\n}\n\n.pagingNum.selected {\n  background-color: var(--primary-color);\n  color: var(--white-color);\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paging": "Paging_paging__ozdJv",
	"pagingNum": "Paging_pagingNum__7Pqzl",
	"selected": "Paging_selected__uzhzl"
};
export default ___CSS_LOADER_EXPORT___;
